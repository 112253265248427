import { useFormContext } from "react-hook-form";


export default function PartE() {
    const { register } = useFormContext();

    return (<>

        <p>
            I HEREBY COVENANT AND AGREE TO PROMPTLY INDEMNIFY AND SAVE HARMLESS
            WILD WEST INDOOR RANGE LTD. and its directors, officers, employees,
            volunteers, representatives and agents from any and all claims,
            damages, demands, actions, causes of action and judgments (including
            judgments for costs) which may be advanced by or on behalf of and or
            awarded to or for the benefit of myself, my heirs and assigns, and
            any minor on whose behalf I have executed this Release, as a result
            of or in any way arising from Wild West granting access to the
            Facilities together with all costs of defending any such claim,
            demand and/or action including all legal fees and disbursements (and
            taxes thereon).
        </p>
        <p>
            If any part of this document shall be unenforceable, the portion
            which is unenforceable shall be severed and the balance of this
            document shall remain in full force and effect. Without limiting the
            generality of the foregoing, if the Release on behalf of myself
            and/or my minor child shall be deemed to be unenforceable, the
            aforesaid Indemnities shall nevertheless remain in full force and
            effect.
        </p>
        <p>
            I HAVE READ THIS RELEASE WAIVER AND INDEMNITY AGREEMENT PRIOR TO
            SIGNING IT AND I UNDERSTAND IT. I FURTHER UNDERSTAND THAT BY SIGNING
            THIS DOCUMENT, I AM AFFECTING THE LEGAL RIGHTS OF MYSELF, MY HEIRS,
            EXECUTORS, ADMINISTRATORS AND MY NEXT OF KIN. I ACKNOWLEDGE THAT A
            COPY OF THIS DOCUMENT WILL BE MADE AVAILABLE TO ME IF I REQUEST.
        </p>
        <p>
            The USER is further obliged to follow the directions, orders, range
            safety rules and facility etiquette polices as posted in and around
            the facility and as verbally expressed by the employees and
            volunteers of Wild West at ALL Times.
        </p>
        <p>
            Information collected on this form shall be treated as confidential
            and will only be stored as required by law, but may be released
            solely at Wild West's discretion to police or other authorities. We
            will not sell or give your information to a commercial third party
            unless required by law.
        </p>
        <p>
            The User agrees any dispute related to this document, and to the
            User's or any minor child's attendance at the Facility shall be
            exclusively governed by the laws of the Province of Alberta and
            applicable federal laws of Canada, and the User hereby irrevocably
            attorns to the jurisdiction of the Courts of Alberta.
        </p>
        <p>
            The User acknowledges and agrees that this waiver shall remain in
            full force and effect for all attendances at Wild West throughout
            subsequent yearly renewals of membership, and despite the fact the
            User may not be or may cease to be a member of Wild West Indoor
            Range Ltd.
        </p>


    </>)
}
