import { useFormContext } from "react-hook-form";


export default function PartC() {
    const { register } = useFormContext();

    return (<>

        <h4>Part C. ASSIGNMENT AND INDEMNITY</h4>
        <p>
            1. I hereby wholly and irrevocably assign to Wild West all rights,
            including statutory or common-law copyright, associated with any
            photography, audio or video recording in any form made created or
            begun at the Facilities that is for any reason posted, shown or in
            any way broadcast in any forum or media for commercial, artistic,
            creative, promotional, educational or informative purposes without
            the express written prior permission of a Director or Officer of
            Wild West.
        </p>
        <p>
            2. I hereby agree to indemnify Wild West, its directors, officers,
            agents, employees and assigns against any and all costs, expenses,
            damages and other losses arising in any way out of the posting or
            broadcast of any photography, audio or video recording in any form
            made created or begun at the Facilities.
        </p>
        <label htmlFor="chkPartC" className="flex flex-row items-center">
            <input
                required
                type="checkbox"

                {...register("chkPartC", { value: false })}
            />
            Confirm you have read and understood Part C.
        </label>


    </>)
}
