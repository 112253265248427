import axios from "axios";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ReactSignatureCanvas from "react-signature-canvas";
import Intro from "./components/Intro";
import PartA from "./components/PartA";
import PartB from "./components/PartB";
import PartC from "./components/PartC";
import PartD from "./components/PartD";
import PartE from "./components/PartE";
import getApiEndpoint from "./lib/getApiEndpoint";

document.title = "Wavier";


interface FormData {
  fullName: string;
  fullName2: string;
  email: string;
  phoneNumber: string;
  chkPartA: boolean;
  chkPartB: boolean;
  chkPartC: boolean;
  chkPartD: boolean;
  chkNotImpared: boolean;
  signature: any;
  minorFullName1: string;
  minorFullName2: string;
  minorFullName3: string;
  minorFullName4: string;
  minorFullName5: string;
  terms: boolean;
}

export default () => {
  const sigRef = useRef<ReactSignatureCanvas>(null);
  const methods = useForm<FormData>({
    defaultValues: {
      fullName: "",
      fullName2: "",
      email: "",
      phoneNumber: "",
      chkPartA: false,
      chkPartB: false,
      chkPartC: false,
      chkPartD: false,
      chkNotImpared: false,
      signature: "",
      minorFullName1: "",
      minorFullName2: "",
      minorFullName3: "",
      minorFullName4: "",
      minorFullName5: "",
      terms: false,


    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const [
    minors,
    setMinors,
  ] = useState(false);
  const [
    optIn,
    setOptIn,
  ] = useState(false);
  const [signed, setSigned] = useState(false);
  const [isError, setIsError] = useState<string>();

  const handleClear = () => {
    if (sigRef.current) {
      sigRef.current.clear();
    }
  };


  const onSubmit = (values: FormData) => {
    console.log(values)
    const data = { ...values, signature: sigRef.current ? sigRef.current.toDataURL() : "" };

    const endpoint = getApiEndpoint();
    if (!endpoint) {
      setIsError("Unable to detect API endpoint. Please try again later.");
      return;
    }
    setIsLoading(true);
    axios({
      method: "POST",
      url: endpoint,
      data: data,
    })
      .then(function (response) {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch(function (response) {

        console.log(response);
        setIsLoading(false);
        setIsError(`Something went wrong. ${response}`);
        setSuccess(false);
      })
      .finally(function () {
        setIsLoading(false);
      });

  };

  function checkOnlineState() {
    if (!navigator.onLine) {
      alert("You are offline. Please connect to the internet to continue.");
    }
  }

  window.addEventListener("offline", checkOnlineState);
  window.addEventListener("online", checkOnlineState);

  checkOnlineState();


  return (
    <div className="w-full">

      <div id="main" className="">
        <h1 className="font-black text-lg text-center p-8">RANGE WAIVER</h1>
        <FormProvider {...methods} >
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {isError && <p style={{ color: "red" }}>{isError}</p>}
            {isLoading && <p>Loading...</p>}
            {success && <p>Success!</p>}
            {success === false && <p>Something went wrong. Please try again.</p>}
            {!isLoading && (<>
              <div className="my-6 px-6"><Intro /></div>
              <div className="my-6 px-6"><PartA /></div>
              <div className="my-6 px-6 bg-gray-100"><PartB /></div>
              <div className="my-6 px-6"><PartC /></div>
              <div className="my-6 px-6"><PartD /></div>
              <div className="my-6 px-6 bg-gray-100"><PartE /></div>

            </>)}

            <div

              className="sigCanvas p-4"
              onClick={() => {
                if (!sigRef.current) {
                  setIsError("Signature Required");
                }
                setSigned(true);
                methods.setValue("signature", sigRef.current?.toDataURL())

              }}
            >
              <p>
                <em>Sign below with your finger</em>
              </p>
              <div>
                <ReactSignatureCanvas
                  ref={sigRef}
                  backgroundColor="#efefef"
                  canvasProps={{
                    width: 335,
                    height: 100,
                    className: "sigCanvas py-4",
                  }}

                />
              </div>
              <button onClick={handleClear} className="relative">Clear</button>
            </div>
            <div className="flex flex-col">

              <input
                required
                type="email"
                placeholder="Email"
                {...methods.register("email")}
              />
              <input
                required
                type="text"
                placeholder="Phone Number"
                {...methods.register("phoneNumber")}
              />
              <label htmlFor="chkPartE" className="inline-flex items-center"><input
                required
                type="checkbox"
                {...methods.register("terms", { value: false })}

              />Confirm you have read and understood this waiver.</label>


              <label htmlFor="chkPartF" className="inline-flex items-center"><input
                type="checkbox"

                name="isReponsibleTermsChecked"
                onChange={(e) =>
                  setMinors(e.target.checked)

                }
              /> Are you responsible for minors?</label>


              {minors && (
                <div>
                  <p>Please list their names</p>
                  <input
                    type="text"
                    placeholder="Full Name of Minor"
                    {...methods.register("minorFullName1")}
                  />
                  <input
                    type="text"
                    placeholder="Full Name of Minor"
                    {...methods.register("minorFullName2")}
                  />
                  <input
                    type="text"
                    placeholder="Full Name of Minor"
                    {...methods.register("minorFullName3")}
                  />
                  <input
                    type="text"
                    placeholder="Full Name of Minor"
                    {...methods.register("minorFullName4")}
                  />

                </div>
              )}
            </div>
            <button
              className="btn-submit display-flex bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"

              type="submit"

            >
              ACCEPT TERMS
            </button>

            <label htmlFor="optin" className="inline-flex items-center">
              <input
                type="checkbox"
                onChange={(e) =>
                  setOptIn(e.target.checked)
                }
              />
              {" "}
              Would you like to receive daily deals and promotional discounts?
            </label>




            {isLoading && <p>Loading...</p>}
            {isError ? (<div
              className="alert alert-danger"
              role="alert"
            >
              {isError}
            </div>) : null}
            {success ? (<div
              className="alert alert-success"
              role="alert"
            >
              {success.valueOf() ? "Success!" : "Error."}
            </div>) : null}
          </form>
        </FormProvider>

      </div>
    </div>
  );
};


