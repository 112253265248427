import { useFormContext } from "react-hook-form";


export default function PartA() {
    const { register } = useFormContext();

    return (<>

        <h4>Part A. ACKNOWLEDGE AND AFFIRM</h4>
        <p>
            1. ACKNOWLEDGE AND AFFIRM that the Facilities include shooting
            ranges where firearms may be legally discharged and may be
            discharged at any time that the Facilities are open or otherwise;
        </p>
        <p>
            2. That firearms, ammunition and other paraphernalia related to
            firearms and the discharge thereof may be present on the Facilities
            at any time or at all times;
        </p>
        <p>
            3. That the use of firearms, ammunition and other paraphernalia
            related to firearms and the discharge thereof is subject to certain
            inherent risks and hazards and that the use of firearms and the
            activities associated therewith is and are potentially dangerous;
        </p>
        <p>
            4. That in entering upon or using the Facilities I do so at my sole
            and absolute risk, notwithstanding that I may or may not be familiar
            with any or all aspects of firearms, ammunition and other related
            paraphernalia, the discharge of firearms or the risks and hazards
            associated therewith;
        </p>
        <p>
            5. That while on or using the Facilities I am absolutely and solely
            responsible for my own conduct, safety and well-being as well as the
            conduct, safety and well-being of any guest accompanying me
            (including without limitation any minor / child);
        </p>
        <p>
            6. That engaging in or observing shooting activities exposes me to
            lead particles and other potentially harmful contaminants, including
            lead and lead vapors;
        </p>
        <p>
            7. That the by-products and emissions from the discharging of
            firearms are potentially toxic to me and that in particular lead
            particles which are emitted by the discharge of firearms is toxic to
            me and anyone accompanying me and that all persons who come into
            contact with such by products and emissions are at risk of incurring
            health problems;
        </p>
        <p>
            8. That anyone under the age of 18 and pregnant women are at higher
            risk of incurring health problems from contact with the by-products
            and emissions from the discharging of firearms;
        </p>
        <p>
            9. That if I or anyone with me enters the shooting areas and moves
            beyond the designated firing line, I and such persons are at greater
            risk of injury or health problems;
        </p>
        <p>
            10. That if I engage in shooting activities which involve shooting
            at points beyond the designated firing line, I assume all risk for
            myself and anyone accompanying me for any injury or harm that I or
            they suffer;
        </p>
        <p>
            11. That while at Wild West, I will follow all warnings and
            instructions posted or given to me and I will practice safe shooting
            habits and behaviors to protect myself and those around me and
            minimize the risk of injury or harm to myself or others;
        </p>
        <p>
            12. That it is my sole responsibility to read and understand and
            that I have read, understood and I agree to execute this Release,
            Waiver and Indemnity Agreement as a condition to obtaining access to
            and use of the Facilities and the activities, services and
            facilities contained and provided therein.
        </p>
        <p>
            13. That any photography, audio or video recording in any form,
            made, created or begun at the Facilities in whole or in part shall
            only be used by me, and shall only be for personal use; it shall not
            be posted, shown or in any way broadcast in any forum or media for
            commercial, artistic, creative, promotional educational or
            informative purposes without the express prior written permission of
            a Director or Officer of Wild West.
        </p>
        <p>
            14. That under no circumstances is any photography, audio or video
            recording in any form made, created or begun at the Facilities in
            whole or in part to be used for any religious or political purpose
            in a public forum or elsewhere, regardless of what I (the User) may
            regard as being 'personal use'
      </p>
      <div className="flex flex-row items-center">

        <input
          type="checkbox"
          className="mr-2"
            {...register("chkPartA", { value: false, required: true })}
      />
      <label htmlFor="chkPartA">
        Confirm you have read and understood Part A.
        </label>
      </div>


        <div className="flex flex-row items-center">
            <input
                type="checkbox"
                {...register("chkNotImpared", { value: false, required: true })}
      />
       <label htmlFor="chkNotImpared" className="flex flex-row items-center">
            I am not under the influence of Alcohol, Marijuana, or any other substance that may affect my cognitive function. I agree that if I am suspected of any impairment that I will be EXPELLED FROM THE RANGE WITH NO REFUND
            </label>
        </div>

    </>)
}
