import { useFormContext } from "react-hook-form";


export default function Intro() {
    const { register } = useFormContext();

    return (<>
        <div>
            <p>
                I hereby acknowledge that I am not under any prohibition from handling, possessing or using firearms and/or ammunition and/or prohibited devices of any nature.(In other words, ask yourself: Would the police or a probation officer be upset if you were here.)
            </p>
            <p className="py-6">
                Be advised, that we make, at our sole discretion, these records readily available to ALL CANADIAN PUBLIC LAW ENFORCEMENT AGENCIES
            </p>
            <p className="font-black">
                Important: This document will affect your legal rights and liabilities. This document also sets out some of your obligations and some of ours. Read the Facility Rules Letter for additional obligations”. Please read carefully before signing.
            </p>
        </div>
        <div>
            <p>I, (print name)</p>
            <input
                className="mb-3 floating form-text"
                required
                type="text"
                placeholder="Full Name"
                {...register("fullName")}
            />
            <p>(“USER”) including all minors that I am legally or ethically responsible for as listed below, irrevocably agree as a condition precedent at all times to my presence upon or use, at any time, of any or all of the facilities of Wild West Indoor Range Ltd. (“Wild West”) including without limitation those facilities located at 2390, 8882 - 170 Street, Edmonton Alberta and the parking facilities located in front of, in the vicinity of or around such location, and any location or facilities where any activity or function sponsored or held by Wild West is located (collectively “the Facilities”), whether for my own purposes, as an invitee of Wild West or as part of a function or activity whether or not such function or activity is sponsored, sanctioned or supported by Wild West, do hereby make the acknowledgements and do grant the waiver and indemnity set out below, in addition to any other consideration which I may or may not have provided or agreed to provide to Wild West:</p>

            <p className="py-6">In this document, the above USER is referred to as I or the USER.</p>


        </div>
        <p className="font-black">
            FOR GOOD AND VALUABLE CONSIDERATION (THE RECEIPT, SUFFICIENCY AND
            ADEQUACY OF WHICH I DO HEREBY ACKNOWLEDGE) I, THE UNDERSIGNED USER
            FOR AND ON BEHALF OF MYSELF, AND FOR MY HEIRS, EXECUTORS AND
            ADMINISTRATORS, DO HEREBY:

        </p></>)
}
