
import Waiver from './Waiver';

function App() {
  return (
    <div className="max-w-[1200px] m-auto p-6">
      <Waiver />
    </div>
  );
}

export default App;
