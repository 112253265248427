import { useFormContext } from "react-hook-form";


export default function PartD() {
    const { register } = useFormContext();

    return (<>
        <h4>Part D. I HEREBY INDEMNIFY</h4>
        <p>
            1. I HEREBY INDEMNIFY and agree to promptly indemnify and hold Wild
            West and its directors, officers, employees, volunteers,
            representatives and agents harmless from and against any and all
            liabilities, claims, demands, actions, causes of action, judgments
            (including judgments for costs), costs or expenses (including legal
            expenses) which Wild West or its directors, officers, employees,
            volunteers, representatives or agents may suffer, sustain or incur
            as a direct or indirect result of any act or omission of any nature
            or kind whatsoever by me or any guest or invitee of myself.
            <br />
            2. I acknowledge that the execution and delivery of this Release
            Waiver and Indemnity Agreement is required as a condition precedent
            of Wild West granting access to the facility and use thereof. I
            further acknowledge and agree that Wild West reserves the right not
            to proceed with granting access or to otherwise discontinuing access
            at any time.
        </p>
        <p>
            <b>
                I warrant and represent to Wild West Indoor Range Ltd. that I have
                authority to sign this Release, Waiver and Indemnity Agreement on
                behalf of my heirs, my executors, administrators and myself.
            </b>
        </p>
        <label htmlFor="chkPartD" className="flex flex-row items-center">
            <input
                required
                type="checkbox"

                {...register("chkPartD", { value: false })}
            />
            Confirm you have read and understood Part D.
        </label>



    </>)
}
