import { useFormContext } from "react-hook-form";


export default function PartB() {
    const { register } = useFormContext();

    return (<>

        <h4>Part B. WAIVE AND RELEASE </h4>
        <p>
            1. WAIVE AND RELEASE Wild West Indoor Range Ltd. and its directors,
            officers, employees, volunteers, representatives and agents from any
            and all claims, demands, damages, actions and causes of action
            arising whether directly or indirectly from or in consequence of any
            and all losses, injury (including loss of life) and/or damage to the
            person and/or property of the USER suffered, sustained or incurred
            during, in connection with or as a result of my presence at or use
            of the Facilities, or while attending or participating in activity
            associated or connected with Wild West, the Facilities or the
            directors, officers, employees, volunteers, representatives and
            agents of Wild West, due to any cause whatsoever including without
            limitation the negligence, gross negligence or willful misconduct of
            Wild West, its directors, officers, employees, volunteers,
            representatives, clients, customers, suppliers, members or agents.
            AND ON BEHALF OF MYSELF, I HEREBY WAIVE ANY AND ALL CLAIMS against
            Wild West and its directors, officers, employees, volunteers,
            representatives and agents for damages, costs and expenses
            (including legal expenses) which I may have, sustain or incur in
            connection with or arising directly or indirectly in any way from my
            presence at or use of the Facilities no matter what the cause may
            be, or while attending or participating in activity associated or
            connected with Wild West including, without limitation, all damages,
            costs and expenses arising from: (a) the provision of services of
            any kind including but not limited to provision of firearms and
            ammunition and the loading and discharge thereof; and (b) the
            provision of insurance or lack thereof;
        </p>
        <label htmlFor="chkPartB" className="flex flex-row items-center">
            <input
                required
                type="checkbox"

                {...register("chkPartB", { value: false })}
            />
            Confirm you have read and understood Part B.
        </label>


    </>)
}
